export enum DappMetricCategory {
  TotalUsers = 'totalUsers',
  UserChange = 'userChange',
  Volume = 'volume',
  VolumeChange = 'volumeChange',
  Balance = 'balance',
  BalanceChange = 'balanceChange',
  Other = 'other',
}

export enum DappMetric {
  TotalUsers24h = 'totalUsers24h',
  TotalUsers7d = 'totalUsers7d',
  TotalUsers30d = 'totalUsers30d',
  UserChange24h = 'userChange24h',
  UserChange7d = 'userChange7d',
  UserChange30d = 'userChange30d',
  Volume24h = 'volume24h',
  Volume7d = 'volume7d',
  Volume30d = 'volume30d',
  VolumeUSD24h = 'volumeUSD24h',
  VolumeUSD7d = 'volumeUSD7d',
  VolumeUSD30d = 'volumeUSD30d',
  VolumeChange24h = 'volumeChange24h',
  VolumeChange7d = 'volumeChange7d',
  VolumeChange30d = 'volumeChange30d',
  Balance24h = 'balance24h',
  Balance7d = 'balance7d',
  Balance30d = 'balance30d',
  BalanceChange24h = 'balanceChange24h',
  BalanceChange7d = 'balanceChange7d',
  BalanceChange30d = 'balanceChange30d',
  Blockchain = 'blockchain',
  Category = 'category',
  Platform = 'platform',
}

const DappMetricCategoryLabels: { [key in DappMetricCategory]: string } = {
  [DappMetricCategory.TotalUsers]: 'coins.metrics.totalUsers',
  [DappMetricCategory.UserChange]: 'coins.metrics.userChange',
  [DappMetricCategory.Volume]: 'coins.metrics.volume',
  [DappMetricCategory.VolumeChange]: 'coins.metrics.volumeChange',
  [DappMetricCategory.Balance]: 'coins.metrics.balance',
  [DappMetricCategory.BalanceChange]: 'coins.metrics.balanceChange',
  [DappMetricCategory.Other]: 'coins.metrics.other',
};

export function getDappMetricCategoryLabelKey(metricCategory: DappMetricCategory): string {
  return DappMetricCategoryLabels[metricCategory];
}

export type DappMetricsOptions = {
  [category in DappMetricCategory]: DappMetric[];
};

export const DAPP_METRICS_OPTIONS: DappMetricsOptions = {
  [DappMetricCategory.TotalUsers]: [
    DappMetric.TotalUsers24h,
    DappMetric.TotalUsers7d,
    DappMetric.TotalUsers30d,
  ],
  [DappMetricCategory.UserChange]: [
    DappMetric.UserChange24h,
    DappMetric.UserChange7d,
    DappMetric.UserChange30d,
  ],
  [DappMetricCategory.Volume]: [
    DappMetric.Volume24h,
    DappMetric.Volume7d,
    DappMetric.Volume30d,
    DappMetric.VolumeUSD24h,
    DappMetric.VolumeUSD7d,
    DappMetric.VolumeUSD30d,
  ],
  [DappMetricCategory.VolumeChange]: [
    DappMetric.VolumeChange24h,
    DappMetric.VolumeChange7d,
    DappMetric.VolumeChange30d,
  ],
  [DappMetricCategory.Balance]: [
    DappMetric.Balance24h,
    DappMetric.Balance7d,
    DappMetric.Balance30d,
  ],
  [DappMetricCategory.BalanceChange]: [
    DappMetric.BalanceChange24h,
    DappMetric.BalanceChange7d,
    DappMetric.BalanceChange30d,
  ],
  [DappMetricCategory.Other]: [
    DappMetric.Blockchain,
    DappMetric.Category,
    // DappMetric.Platform
  ],
};

export const DAPP_METRICS_DEFAULT: DappMetric[] = [
  DappMetric.Category,
  DappMetric.Blockchain,
  DappMetric.Balance24h,
  DappMetric.BalanceChange24h,
  DappMetric.TotalUsers24h,
  DappMetric.UserChange24h,
  DappMetric.Volume24h,
];
