import { isNullish } from 'remeda';

export const DEFAULT_METRIC_NULLISH_VALUE = '-';

export function resolveMetricNullishValue(
  value: number | null | undefined,
  formatter: (value: number, ...args: any[]) => string,
  options: { formatterArgs?: any[]; defaultValue?: string } = {},
): string {
  const { formatterArgs = [], defaultValue = DEFAULT_METRIC_NULLISH_VALUE } = options;

  return isNullish(value) ? defaultValue : formatter(value, ...formatterArgs);
}
