import type { AppCurrency } from '~/data/domain/currency';
import type { ItemsListRequestParams } from '~/shared/types/items-list.type';
import type { GetDappsRequestParamsMapper } from '~/features/dapps/models/dapp-ranking.model';
import type { AppLocale } from '~/data/domain/app.locale';

interface RankigListParams {
  paginationData: ItemsListRequestParams;
  sortData: GetDappsRequestParamsMapper;
  currency: AppCurrency;
}

export const dappKeys = {
  all: [{ scope: 'dapp' }] as const,
  lists: () => [{ ...dappKeys.all[0], entity: 'list' }] as const,
  topVolumeList: (currency: AppCurrency) =>
    [{ ...dappKeys.lists()[0], entity: 'topVolumeList', currency }] as const,
  rankingList: ({ paginationData, sortData, currency }: RankigListParams) =>
    [
      { ...dappKeys.lists()[0], entity: 'rankingList', paginationData, sortData, currency },
    ] as const,
  details: () => [{ ...dappKeys.all[0], entity: 'detail' }] as const,
  detail: (slug: string, currency: AppCurrency, locale: AppLocale) =>
    [{ ...dappKeys.details()[0], slug, currency, locale }] as const,
};
