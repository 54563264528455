import { DappMetric } from '~/data/domain/metrics/dapp-metrics';
import type { BackendDappModel } from '~/data/services/types/dapp.type';
import type { CoinSocialLink } from '~/data/model/coin-social-link.model';

export interface CoinTag {
  id: string;
  name: string;
  slug: string;
}

export interface CoinToken {
  title: string | null;
  icon: string | null;
  priceChange: number | null;
  volume: number | null;
}

export interface DappModel {
  title: string;
  icon: string | null;
  [DappMetric.Volume24h]: number | null;
  [DappMetric.TotalUsers24h]: number | null;
  [DappMetric.VolumeChange24h]: number | null;
  [DappMetric.Balance24h]: number | null;
  [DappMetric.Blockchain]: string | null;
  [DappMetric.Category]: string | null;
  [DappMetric.Platform]: string | null;
  website: string | null;
  fullDescription: string | null;
  socialLinks: CoinSocialLink[];
  tags: CoinTag[];
  token: CoinToken | null;
}

export function createDappModel(raw: BackendDappModel): DappModel {
  return {
    title: raw.title,
    icon: raw.icon,
    [DappMetric.Volume24h]: raw.volume,
    [DappMetric.TotalUsers24h]: raw.total_users_24h,
    [DappMetric.VolumeChange24h]: raw.volume_change_24h,
    [DappMetric.Balance24h]: raw.balance,
    [DappMetric.Blockchain]: raw.blockchain_name,
    [DappMetric.Category]: raw.category_name,
    [DappMetric.Platform]: raw.platform,
    website: raw.website,
    fullDescription: raw.full_description,
    socialLinks: raw.social_links,
    tags: raw.tags,
    token: {
      title: raw.crypto?.title ?? null,
      icon: raw.crypto?.icon ?? null,
      priceChange: raw.crypto?.price_change ?? null,
      volume: raw.crypto?.volume ?? null,
    },
  };
}
