import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import type { ItemsListWithTotalResponse } from '~/shared/types/items-list.type';
import type { AppLocale } from '~/data/domain/app.locale';
import type { BackendDappModel } from '~/data/services/types/dapp.type';
import type {
  BackendDappRankingModelShort,
  BackendRankingDappModel,
} from '~/features/dapps/services/dapp-ranking.type';
import { createDappModel, type DappModel } from '~/features/dapps/models/dapp.model';
import {
  createDappRankingModel,
  createDappRankingModelShort,
  type DappRankingModel,
  type FormattedDappRankingModelShortWithPosition,
  getDappsRequestParamsMapper,
} from '~/features/dapps/models/dapp-ranking.model';
import type { QueryFunctionContext } from '@tanstack/vue-query';
import type { dappKeys } from '~/features/dapps/queries/dapp.query';

export const DappService = (fetch: CustomFetch) => ({
  async getDappsTopVolume(
    locale: AppLocale,
    {
      queryKey: [{ currency }],
    }: QueryFunctionContext<ReturnType<(typeof dappKeys)['topVolumeList']>>,
  ): Promise<FormattedDappRankingModelShortWithPosition[]> {
    const dapps: BackendDappRankingModelShort[] = await fetch('api/main-page/top-volume-dapps', {
      credentials: 'include',
    });

    return dapps.map((item, index) => ({
      position: index + 1,
      ...createDappRankingModelShort(item, locale, currency),
    }));
  },

  async getDappsRanking(
    {
      queryKey: [{ paginationData, sortData, currency }],
    }: QueryFunctionContext<ReturnType<(typeof dappKeys)['rankingList']>>,
    locale: AppLocale,
  ): Promise<ItemsListWithTotalResponse<DappRankingModel>> {
    const result = await fetch('api/ranking/dapps', {
      credentials: 'include',
      params: getDappsRequestParamsMapper(paginationData, sortData),
    });

    return {
      total: result.total,
      data: result.data.map((item: BackendRankingDappModel) => {
        return createDappRankingModel(item, locale, currency);
      }),
    };
  },

  async getDapp({
    queryKey: [{ slug }],
  }: QueryFunctionContext<ReturnType<(typeof dappKeys)['detail']>>): Promise<DappModel> {
    const dapp: BackendDappModel = await fetch(`api/page/dapp/${slug}`, {
      credentials: 'include',
    });

    return createDappModel(dapp);
  },
});
