import type { AppLocale } from '~/data/domain/app.locale';
import type { AppCurrency } from '~/data/domain/currency';
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '~/data/domain/constants';
import { formatNumber, formatPercentage, formatPrice } from '~/utils/number-formatter';
import { resolveMetricNullishValue } from '~/utils/resolve-metric-nullish-value';
import type { ItemsListRequestParams } from '~/shared/types/items-list.type';
import { itemsListRequestParamsMapper } from '~/utils/items-list-request-params-mapper';
import { DappMetric } from '~/data/domain/metrics/dapp-metrics';
import type {
  BackendDappRankingModelShort,
  BackendGetDappsRequestParams,
  BackendRankingDappData,
  BackendRankingDappModel,
} from '~/features/dapps/services/dapp-ranking.type';

//  DappRankingModelShort
export interface DappRankingModelShort {
  id: number;
  title: string;
  icon: string | null;
  slug: string;
  [DappMetric.Balance24h]: number | null;
  [DappMetric.Volume24h]: number | null;
}

export type FormattedDappRankingModelShort = DappRankingModelShort & {
  volume24hFormatted: string;
  balance24hFormatted: string;
};

export interface FormattedDappRankingModelShortWithPosition extends FormattedDappRankingModelShort {
  position: number;
}

function dappPriceFormatter(
  value: number | null | undefined,
  maximumFractionDigits = 0,
  locale: AppLocale,
  currency: AppCurrency,
): string {
  return resolveMetricNullishValue(value, formatPrice, {
    formatterArgs: [locale, currency, { notation: 'compact', maximumFractionDigits }],
  });
}

export function formatDappRankingModelShortMetrics(
  baseModel: DappRankingModelShort,
  locale: AppLocale,
  currency: AppCurrency,
) {
  return {
    balance24hFormatted: dappPriceFormatter(baseModel[DappMetric.Balance24h], 2, locale, currency),
    volume24hFormatted: dappPriceFormatter(baseModel[DappMetric.Volume24h], 1, locale, currency),
  };
}

export function createDappRankingModelShort(
  raw: BackendDappRankingModelShort,
  locale: AppLocale = DEFAULT_LOCALE,
  currency: AppCurrency = DEFAULT_CURRENCY,
): FormattedDappRankingModelShort {
  const baseModel: DappRankingModelShort = {
    id: raw.id,
    title: raw.title,
    slug: raw.slug,
    icon: raw.icon,
    [DappMetric.Volume24h]: raw.volume_24h ?? null,
    [DappMetric.Balance24h]: raw.balance_24h ?? null,
  };

  return {
    ...baseModel,
    ...formatDappRankingModelShortMetrics(baseModel, locale, currency),
  };
}

// DappRankingModel
export interface DappRankingModelMetrics {
  [DappMetric.TotalUsers24h]: number | null;
  [DappMetric.TotalUsers7d]: number | null;
  [DappMetric.TotalUsers30d]: number | null;
  [DappMetric.UserChange24h]: number | null;
  [DappMetric.UserChange7d]: number | null;
  [DappMetric.UserChange30d]: number | null;
  [DappMetric.Volume24h]: number | null;
  [DappMetric.Volume7d]: number | null;
  [DappMetric.Volume30d]: number | null;
  [DappMetric.VolumeUSD24h]: number | null;
  [DappMetric.VolumeUSD7d]: number | null;
  [DappMetric.VolumeUSD30d]: number | null;
  [DappMetric.VolumeChange24h]: number | null;
  [DappMetric.VolumeChange7d]: number | null;
  [DappMetric.VolumeChange30d]: number | null;
  [DappMetric.Balance24h]: number | null;
  [DappMetric.Balance7d]: number | null;
  [DappMetric.Balance30d]: number | null;
  [DappMetric.BalanceChange24h]: number | null;
  [DappMetric.BalanceChange7d]: number | null;
  [DappMetric.BalanceChange30d]: number | null;
}

export interface DappRankingModel extends DappRankingModelMetrics {
  id: number;
  title: string;
  slug: string;
  icon: string | null;
  [DappMetric.Blockchain]: number | null;
  [DappMetric.Category]: number | null;
  // [DappMetric.Platform]: string | null;
}

export type FormattedDappRankingModel = DappRankingModel & {
  [K in keyof ReturnType<typeof formatDappRankingData>]: ReturnType<
    typeof formatDappRankingData
  >[K];
};

export function formatDappRankingData(
  model: DappRankingModel,
  locale: AppLocale,
  currency: AppCurrency,
) {
  const formatDappPrice = (value: number | null, maximumFractionDigits = 0) =>
    dappPriceFormatter(value, maximumFractionDigits, locale, currency);

  return {
    balance24hFormatted: formatDappPrice(model[DappMetric.Balance24h], 2),
    balance7dFormatted: formatDappPrice(model[DappMetric.Balance7d], 2),
    balance30dFormatted: formatDappPrice(model[DappMetric.Balance30d], 2),
    volume24hFormatted: formatDappPrice(model[DappMetric.Volume24h]),
    volume7dFormatted: formatDappPrice(model[DappMetric.Volume7d]),
    volume30dFormatted: formatDappPrice(model[DappMetric.Volume30d]),
    volumeUSD24hFormatted: formatDappPrice(model[DappMetric.VolumeUSD24h]),
    volumeUSD7dFormatted: formatDappPrice(model[DappMetric.VolumeUSD7d]),
    volumeUSD30dFormatted: formatDappPrice(model[DappMetric.VolumeUSD30d]),
    totalUsers24hFormatted: resolveMetricNullishValue(
      model[DappMetric.TotalUsers24h],
      formatNumber,
      {
        formatterArgs: [locale, { notation: 'compact', maximumFractionDigits: 2 }],
      },
    ),
    totalUsers7dFormatted: resolveMetricNullishValue(model[DappMetric.TotalUsers7d], formatNumber, {
      formatterArgs: [locale, { notation: 'compact', maximumFractionDigits: 2 }],
    }),
    totalUsers30dFormatted: resolveMetricNullishValue(
      model[DappMetric.TotalUsers30d],
      formatNumber,
      {
        formatterArgs: [locale, { notation: 'compact', maximumFractionDigits: 2 }],
      },
    ),
    balanceChange24hFormatted: resolveMetricNullishValue(
      model[DappMetric.BalanceChange24h],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    balanceChange7dFormatted: resolveMetricNullishValue(
      model[DappMetric.BalanceChange7d],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    balanceChange30dFormatted: resolveMetricNullishValue(
      model[DappMetric.BalanceChange30d],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    userChange24hFormatted: resolveMetricNullishValue(
      model[DappMetric.UserChange24h],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    userChange7dFormatted: resolveMetricNullishValue(
      model[DappMetric.UserChange7d],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    userChange30dFormatted: resolveMetricNullishValue(
      model[DappMetric.UserChange30d],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    volumeChange24hFormatted: resolveMetricNullishValue(
      model[DappMetric.VolumeChange24h],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    volumeChange7dFormatted: resolveMetricNullishValue(
      model[DappMetric.VolumeChange7d],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
    volumeChange30dFormatted: resolveMetricNullishValue(
      model[DappMetric.VolumeChange30d],
      formatPercentage,
      {
        formatterArgs: [{ modular: true }],
      },
    ),
  };
}

export function createDappRankingModel(
  raw: BackendRankingDappModel,
  locale: AppLocale = DEFAULT_LOCALE,
  currency: AppCurrency = DEFAULT_CURRENCY,
): FormattedDappRankingModel {
  const baseModel: DappRankingModel = {
    id: raw.id,
    title: raw.title,
    slug: raw.slug,
    icon: raw.icon,
    [DappMetric.Category]: raw.category_id ?? null,
    [DappMetric.Blockchain]: raw.blockchain_id ?? null,
    // [DappMetric.Platform]: raw.platform ?? null,
    [DappMetric.TotalUsers24h]: raw.dapp_data?.total_users_24h ?? null,
    [DappMetric.TotalUsers7d]: raw.dapp_data?.total_users_7d ?? null,
    [DappMetric.TotalUsers30d]: raw.dapp_data?.total_users_30d ?? null,
    [DappMetric.UserChange24h]: raw.dapp_data?.users_change_24h ?? null,
    [DappMetric.UserChange7d]: raw.dapp_data?.users_change_7d ?? null,
    [DappMetric.UserChange30d]: raw.dapp_data?.users_change_30d ?? null,
    [DappMetric.Volume24h]: raw.dapp_data?.volume_24h ?? null,
    [DappMetric.Volume7d]: raw.dapp_data?.volume_7d ?? null,
    [DappMetric.Volume30d]: raw.dapp_data?.volume_30d ?? null,
    [DappMetric.VolumeUSD24h]: raw.dapp_data?.volume_usd_24h ?? null,
    [DappMetric.VolumeUSD7d]: raw.dapp_data?.volume_usd_7d ?? null,
    [DappMetric.VolumeUSD30d]: raw.dapp_data?.volume_usd_30d ?? null,
    [DappMetric.VolumeChange24h]: raw.dapp_data?.volume_change_24h ?? null,
    [DappMetric.VolumeChange7d]: raw.dapp_data?.volume_change_7d ?? null,
    [DappMetric.VolumeChange30d]: raw.dapp_data?.volume_change_30d ?? null,
    [DappMetric.Balance24h]: raw.dapp_data?.balance_24h ?? null,
    [DappMetric.Balance7d]: raw.dapp_data?.balance_7d ?? null,
    [DappMetric.Balance30d]: raw.dapp_data?.balance_30d ?? null,
    [DappMetric.BalanceChange24h]: raw.dapp_data?.balance_change_24h ?? null,
    [DappMetric.BalanceChange7d]: raw.dapp_data?.balance_change_7d ?? null,
    [DappMetric.BalanceChange30d]: raw.dapp_data?.balance_change_30d ?? null,
  };

  return {
    ...baseModel,
    ...formatDappRankingData(baseModel, locale, currency),
  };
}

export interface GetDappsRequestParamsMapper {
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  blockchainId?: number;
  categoryId?: number;
}

export function getDappsRequestParamsMapper(
  paginationData: ItemsListRequestParams,
  sortData: GetDappsRequestParamsMapper,
): BackendGetDappsRequestParams {
  return {
    ...itemsListRequestParamsMapper(paginationData),
    sort_by: sortData.sortBy,
    sort_direction: sortData.sortDirection,
    blockchain_id: sortData.blockchainId,
    category_id: sortData.categoryId,
  };
}

export const dappFieldMapping: {
  [key in keyof DappRankingModel]?: keyof BackendRankingDappModel | keyof BackendRankingDappData;
} = {
  title: 'title',
  [DappMetric.TotalUsers24h]: 'total_users_24h',
  [DappMetric.TotalUsers7d]: 'total_users_7d',
  [DappMetric.TotalUsers30d]: 'total_users_30d',
  [DappMetric.UserChange24h]: 'users_change_24h',
  [DappMetric.UserChange7d]: 'users_change_7d',
  [DappMetric.UserChange30d]: 'users_change_30d',
  [DappMetric.Volume24h]: 'volume_24h',
  [DappMetric.Volume7d]: 'volume_7d',
  [DappMetric.Volume30d]: 'volume_30d',
  [DappMetric.VolumeUSD24h]: 'volume_usd_24h',
  [DappMetric.VolumeUSD7d]: 'volume_usd_7d',
  [DappMetric.VolumeUSD30d]: 'volume_usd_30d',
  [DappMetric.VolumeChange24h]: 'volume_change_24h',
  [DappMetric.VolumeChange7d]: 'volume_change_7d',
  [DappMetric.VolumeChange30d]: 'volume_change_30d',
  [DappMetric.Balance24h]: 'balance_24h',
  [DappMetric.Balance7d]: 'balance_7d',
  [DappMetric.Balance30d]: 'balance_30d',
  [DappMetric.BalanceChange24h]: 'balance_change_24h',
  [DappMetric.BalanceChange7d]: 'balance_change_7d',
  [DappMetric.BalanceChange30d]: 'balance_change_30d',
  [DappMetric.Category]: 'category_id',
  [DappMetric.Blockchain]: 'blockchain_id',
  // [DappMetric.Platform]: 'platform',
};

export function getDappFieldForBackend(
  feField: keyof DappRankingModel,
): keyof BackendRankingDappModel | keyof BackendRankingDappData | undefined {
  return dappFieldMapping[feField];
}
